import React, {useState, useEffect} from 'react';

import './Common.css';

const Divider = () => {
    return (
        <div className="lineDivider">
            
        </div>
    )
}

export default Divider