import React, {useState, useEffect} from 'react';
import Sketch from "react-p5";
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import './ContentMenu.css';
import Data from '../../Data/Data';
import '../../App.css';
import { FaGithub, FaEnvelope, FaLinkedin, FaPaperclip, FaRocket, FaFileCode, FaUser, FaTrophy, FaStar, FaAward } from 'react-icons/fa';

const ContentMenu = ({ onSubButtonClick, activeButton, setActiveButton, activeSubButton, setActiveSubButton, headerLock}) => {

    
    const [hoveredButton, setHoveredButton] = useState('');

    if (headerLock == true){
        document.getElementById("contentMenu").classList.add('contentMenuLocked')
    } 
    else if(headerLock == false){
        if(document.getElementById("contentMenu")){
            document.getElementById("contentMenu").classList.remove('contentMenuLocked')
        }
        
    }

    const activateButton = (e) => {

        //if (activeButton) { //deactivate button
        //    var myActiveButton = document.getElementById(activeButton);
        //    myActiveButton.childNodes[1].firstChild.style.width = "0%"
            
            //if (myActiveButton.childNodes[2]) {
            //    var subMenuContent = myActiveButton.childNodes[2];
            //    subMenuContent.style.opacity = 0;
            //    subMenuContent.style.display = "None";
            //    subMenuContent.style.backgroundColor = "blue";
            //}
        //}
        

    
        //if (e.target.id && e.target.id != "Bio" && e.target.id != "Skills") {
        //    setActiveButton(e.target.id);
        //    var menuItem = document.getElementById(e.target.id);
        //    var rectangle = menuItem.childNodes[1].firstChild;
        //    rectangle.style.width = "100%";
        //   
        //}
        
        
        
    }

    const activateSubButton = (e) => {
        
        if(activeSubButton){
            //document.getElementById(activeSubButton).firstChild.style.height = 0;
        }

        setActiveSubButton(e.target.id)
        onSubButtonClick(e);
        var myActiveSubButton = document.getElementById(e.target.id)
        //myActiveSubButton.firstChild.style.height = "calc(.65em + .65vw)";
    }

    const mouseHover = (e) => {
        /*if (hoveredButton) {
            var menuItem = document.getElementById(hoveredButton);
            var menuIcon = menuItem.childNodes[1].firstChild;
            if (activeButton != hoveredButton) { 
                menuIcon.style.width = "0%"; //hide horizontal rectangle
            }
            if (menuItem.childNodes[2]){
                var subMenuContent = menuItem.childNodes[2];
                subMenuContent.style.opacity = 1;
                subMenuContent.style.fontSize = "calc(.35em + .35vw)";
                subMenuContent.style.display = "inline";
            }
            
        }
        if (document.getElementById(e.target.id)) {
            setHoveredButton(e.target.id)
            var menuItem = document.getElementById(e.target.id);
            var menuIcon = menuItem.childNodes[1].firstChild;
            menuIcon.style.width = "100%";
        }
        */
    }

    const mouseLeave = (e) => {
        if (e.target.id && e.target.id != activeButton) {
            var leftItem = document.getElementById(e.target.id);
            var leftIcon = leftItem.childNodes[1].firstChild;
            leftIcon.style.width = "0%"
            if (leftItem.childNodes[2]) {
                var subMenuContent = leftItem.childNodes[2];
                subMenuContent.style.opacity = 0;
                subMenuContent.style.display = "none";
            }
        }
        
    }
//onMouseEnter={e=>{mouseEnter(e)}} onMouseLeave={e=>{mouseLeave(e)}} onMouseOver={e=>{mouseHover(e)}} onClick={e=>{activateButton(e)}}
    const mouseEnter = (e) => {

    }

    /*
    <div className="mediumText subMenuContainer">
                        <div id="Bio" className="subMenuContent" onClick={e=>{activateSubButton(e)}}>
                            <div Data="hi" id="Bio" className="subMenuLine">   
                            </div>
                            <div Data="hi" id="Bio">
                                Bio
                            </div>
                        </div>
                        <div id="Skills" className="mediumText subMenuContent" onClick={e=>{activateSubButton(e)}}>
                            <div id="Skills" className="subMenuLine">   
                            </div>
                            <div id="Skills">
                                Skills
                            </div>
                        </div>
                        <div className="mediumText subMenuContent">
                            <div className="subMenuLine">      
                            </div>
                            <div>
                                Reading list
                            </div>
                        </div>
                    </div>
                    */
    return (
        <div id="contentMenu" className="contentMenu flexSpaceBetween">
            <div className="inlineContainer">
            <div className="menuFlexBox">
                <div id="About" className="contentMenuItem">
                    <Link activeClass="active" className="flexContainer" to="aboutMe" spy={true} offset={-110} smooth={true} duration={500} id="About">
                        <FaUser id="About" className="contentMenuIcon"/>
                        <div>About me</div>
                    </Link>
                    <div id="About" className="rectangleContainer">
                        <div className="expandingRectangle">
                        </div>
                    </div>
                    
                </div>
                <div id="Experience" className="contentMenuItem" onClick={e=>{activateButton(e)}}>
                    <Link activeClass="active" to="experience" spy={true} smooth={true} duration={500} offset={-100} id="Experience"className="flexContainer">
                        <FaStar id="Experience" className="contentMenuIcon"></FaStar>
                        <div id="Experience">Experience</div>
                    </Link>
                    <div className="rectangleContainer">
                        <div className="expandingRectangle">
                        </div>
                    </div>
                    
                </div>
                <div id="Projects" className="contentMenuItem" onClick={e=>{activateButton(e)}}>
                    <Link activeClass="active" to="projects" spy={true} smooth={true} duration={500} offset={-100} id="Projects" className="flexContainer">
                        <FaFileCode id="Projects" className="contentMenuIcon"></FaFileCode>
                        <div id="Projects">Projects</div>
                    </Link>
                    <div className="rectangleContainer">
                        <div className="expandingRectangle">
                        </div>
                    </div>
                </div>
                
            </div>
            
            </div>
            <div className="linkContainer iconSize">
                <a href="https://www.linkedin.com/in/hapa1/" className="FAIconContainer">
                    <FaLinkedin/>
                </a>
                <a href="https://github.com/Hapa1" className="FAIconContainer">
                    <FaGithub/>
                </a>
            </div> 
        </div>
        
    )
}

export default ContentMenu

/*
<div className="mediumText subMenuContainer">
                        <div className="subMenuContent">
                            <div className="subMenuLine">   
                                 
                            </div>
                            <div>
                                Work experience
                            </div>
                        </div>
                        <div className="mediumText subMenuContent">
                            <div className="subMenuLine">     
                            </div>
                            <div>
                                Certifications
                            </div>
                        </div>
                        
                    </div>


<div id="Future" className="contentMenuItem" onClick={e=>{activateButton(e)}}>
                    <div id="Future" className="flexContainer">
                        <FaRocket id="Future" className="contentMenuIcon"/>
                        <div id="Future">Endeavours</div>
                    </div>
                    <div className="rectangleContainer">
                        <div className="expandingRectangle">
                        </div>
                    </div>
                </div>

                    
*/