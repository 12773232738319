import React, {useState, useEffect} from 'react';

import './About.css';
import Data from '../../Data/Data';
import '../../App.css';
import '../Common/Common.css';
import Divider from '../Common/Divider.js';
import { FaMicrosoft, FaCode, FaLaptop, FaBook, FaAws, FaCodeBranch, FaServer, FaReact, FaDatabase, FaCircle, FaFontAwesome } from 'react-icons/fa';
import MultiBulletComponent from '../CoolComponents/bulletComponent/multiBulletComponent'
import BadgeComponent from '../CoolComponents/badgeComponent'
import CardComponent from '../CoolComponents/cardComponent'
import AboutComponent from '../CoolComponents/aboutComponent'
import SkillComponent from '../CoolComponents/skillComponent'
import ExperienceComponent from '../CoolComponents/experienceComponent'


const About = () => {

    const listSkills = () => {
        var skills = []
        for (let i = 0; i < Data.consultingSkills.length; i++){
            skills.push(
            <div className="skillLine">
                <b style={{color:'#2c8cff'}}>•</b> {Data.consultingSkills[i]}
            </div>
            );
        }
        return skills
    }

    return (
        <div>
            <Divider></Divider>
            <div className="bioContainer">
                <div className="bioContent">
                    <div name="aboutMe" className="contentNSection">
                        <div className="AboutContentContainer">
                            <div className="contentNHeader">
                                <b>Intro</b>
                            </div>
                            <div>
                                <AboutComponent/>
                            </div>
                        </div>  
                    </div>
                    <div className="contentNSection">
                        <div className="AboutContentContainer">
                            <div className="contentNHeader">
                                <b>Tech Stack</b>
                            </div>
                            <div className="aboutContentText">
                                <div className="skillCategoryContainer">
                                    <SkillComponent iconParam="FaCode" title="Languages" badges={['C#','T-SQL', 'JavaScript','Python']}/>
                                    <SkillComponent iconParam="FaLaptop" title="SaaS" badges={['Microsoft Dynamics','PowerApps','Power Automate']}/>
                                    <SkillComponent iconParam="FaChartLine" title="Data/Analytics" badges={['Azure Data Factory', 'Azure Synapse','Azure SQL','Power BI','SSIS','SSRS']}/>
                                    <SkillComponent iconParam="FaReact" title="Frontend" badges={['HTML/CSS','React.js','ES6']}/>
                                    <SkillComponent iconParam="FaCodeBranch" title="Tools" badges={['Git','Azure DevOps']}/>
                                    <SkillComponent iconParam="FaMicrosoft" title="Azure" badges={['API Management','Function Apps','Service Bus','Logic Apps','ARM Templates','App Services','Log Analytics','Azure API for FHIR']}/>
                                </div>
                            </div>
                        </div>  
                    </div>
                    <div name="experience" className="contentNSection">
                        <div className="AboutContentContainer">
                            <div className="contentNHeader">
                                <b>Experience</b>
                            </div>
                            <div>
                                <ExperienceComponent/>
                            </div>
                        </div>  
                    </div>
                    <div name="projects"className="contentNSection">
                        <div className="AboutContentContainer">
                            <div className="contentNHeader">
                                <b>Project Highlights</b>
                            </div>
                            <div>
                                <CardComponent 
                                    backgroundColor='#edf4e2'
                                    badges={['React.js','Node.js','GraphQL','Apollo','MongoDB','Google Maps API','Chart.js','Flask','Raspberry Pi Zero']} 
                                    title='Grouch' 
                                    description='Grouch is the counterpart webapp for the Grouch IoT device that displays geospatial waste bin data and analytics. Developed for the 2019 Paseo Prototyping challenge'
                                    imgSrc='Draft.png'
                                    url='https://github.com/Hapa1/Grouch'
                                />
                                <CardComponent 
                                    badges={['React.js','Node.js','MongoDB','Redux','Express','Passport.js','Google People API', 'OAuth2','Stripe API','Sendgrid API']} 
                                    title='Survey.me' 
                                    description='Used sendgrid, single sign on, and Stripe to create a fullstack webapp where users can send email recipients surveys and view repsonses' 
                                    imgSrc='survey.png'
                                    url='https://github.com/Hapa1/Survey.me'
                                />
                                <CardComponent
                                    badges={['React.js','Node.js','Express','DynamoDB','Socket.io']} 
                                    title='Hapa Chat' 
                                    description='Webapp for connecting individuals during quarantine.' 
                                    imgSrc='hapa.png'
                                    url='https://github.com/Hapa1/Chatter'
                                />
                                
                            </div>
                        </div>  
                    </div>
                </div>
                
            </div>
            <Divider></Divider>
            
            
        </div>
    )
}

export default About