import React, {useState, useEffect} from 'react';
import './Dashboard.css';
import '../../App.css';
import ContentMenu from '../Content/ContentMenu.js'
import About from './About.js'
import Footer from './Footer.js'

const Dashboard = () => {

    const [activeButton, setActiveButton] = useState('About');
    const [activeSubButton, setActiveSubButton] = useState('');
    const [headerLock, setHeaderLock] = useState(false);

    const handleScroll = () => {

        if(document.getElementById("mainContainer")){
            var titleHeight = document.getElementById("mainContainer").offsetHeight;
            var scrollY = window.scrollY

            if (scrollY < titleHeight && headerLock == true){
                setHeaderLock(false);
                document.getElementById("activeContentContainer").style.paddingTop = '0px';
            }
            if (scrollY > titleHeight && headerLock == false){
                setHeaderLock(true);
                document.getElementById("activeContentContainer").style.paddingTop = '73px';
            }
        }
    }

   window.addEventListener("scroll",handleScroll);

    return(
        <div>
            <div id="mainContainer" className="mainContainer">
                <div className="jumbotronContainer"/>
                <div className="titleContainer">
                    <div className="title">
                        Ryan Moore
                    </div>
                </div>
                <div className="aboutContainer">
                <div>
                </div>
                <div className="about">
                            Developer and Consultant
                </div>
                <div/>
            </div>
            </div>
            
            <ContentMenu 
                headerLock={headerLock}
                setHeaderLock={setHeaderLock}
            />
            <div id="activeContentContainer" className="activeContentContainer"><div className="activeContent"><About/></div></div>
            <Footer/>
        </div>
        
    );
}

export default Dashboard