import React from 'react'
import BadgeComponent from './badgeComponent'
import '../../App.css';

const experienceComponent = () => {

    let badges = ['Java','React','Python']
    let title = 'Grouch'
    let description = 'Client for IoT device to display waste analytics'

    const returnBadges = () => {
        let badgeList = badges.map(badge => 
            <div>
                <BadgeComponent text={badge}/>
            </div>
        );
        return badgeList
    }

    return (
        <div className="cardComponent">
            <div className="container">

                <div className="marginLeft MSFTSummaryGrid">
                    <div className="center marginTop">
                        <div className="aboutCardSummaryName bigText blueText">
                            <b>Consultant</b>
                        </div>
                        <div className="aboutCardSummaryLocation mediumText">
                            <b>Microsoft</b>
                        </div>
                        <div className="aboutCardSummaryDetail">
                            2021 - Present
                        </div>
                        <div className="aboutCardSummaryDetail">
                            Dallas, TX
                        </div>
                    </div>
                </div>
                <div className="mediumText MSFTMainGrid marginRight paddingRight">
                    <ul className="liText paddingRight">
                        <li>Architected and developed solution to integrate data from over a hundred disparate EHR systems with Microsoft Dynamics using Azure API Management, Azure Service Bus, Azure Data Lake, Event Grid, Function Apps, Cosmos DB, and Log Analytics.</li>
                        <li>Wrote custom ARM templates and CI/CD pipelines in Azure DevOps for automating the deployment and regression testing of Azure resources.</li>
                    </ul>
                </div>

                <div className="aboutCardDivider dividerGrid1"/>

                <div className="marginLeft DDSSummaryGrid">
                    <div className="center marginTop">
                        <div className="aboutCardSummaryName bigText blueText">
                            <b>Application Developer</b>
                        </div>
                        <div className="aboutCardSummaryLocation mediumText">
                            <b>State of California</b>
                        </div>
                        <div className="aboutCardSummaryDetail">
                            2020 - 2021
                        </div>
                        <div className="aboutCardSummaryDetail">
                            Sacramento, CA
                        </div>
                    </div>
                </div>
                <div className="mediumText DDSMainGrid marginRight paddingRight">
                    <ul className="liText paddingRight">
                        <li>Developed ETL processes for Microsoft Dynamics in C#, SSIS, SQL Server, and Power Automate.</li>
                        <li>Designed reporting solutions using PowerBI and PowerApps</li>
                    </ul>
                </div>

                <div className="aboutCardDivider dividerGrid2"/>

                <div className="marginLeft AvatarSummaryGrid">
                    <div className="center marginTop">
                        <div className="aboutCardSummaryName bigText blueText">
                            <b>Associate Consultant</b>
                        </div>
                        <div className="aboutCardSummaryLocation">
                            <b>RSM US LLP</b>
                        </div>
                        <div className="aboutCardSummaryDetail">
                            2019 - 2020
                        </div>
                        <div className="aboutCardSummaryDetail">
                            San Jose, CA
                        </div>
                    </div>
                </div>
                <div className="mediumText AvatarMainGrid marginRight paddingRight">
                    <ul className="liText paddingRight">
                        <li>Architected and developed PowerBI, PowerApp, and SSRS solutions for client’s Microsoft Dynamics implementation.</li>
                    </ul>
                </div>
    
                <div className="aboutCardDivider dividerGrid3"/>

                <div className="paddingLeft bonusSummaryGrid width100">
                    <div className="center marginTop">
                        <div className="aboutCardSummaryName bigText blueText">
                            <b>Web Developer</b>
                        </div>
                        <div className="aboutCardSummaryLocation">
                            <b>H&A Marketing and Communications</b>
                        </div>
                        <div className="aboutCardSummaryDetail">
                            2018 - 2019
                        </div>
                        <div className="aboutCardSummaryDetail">
                            San Jose, CA
                        </div>
                    </div>
                </div>
                <div className="bonusMainGrid">
                    <div className="educationName">
                        <ul className="liText  marginRight paddingRight">
                            <li>Developed LAMP stack application that enabled users to browse and purchase tickets for upcoming events happening at the San Jose State Hammer Theater Center.</li>
                        </ul>
                    </div>
                    <div>
                        
                    </div>
                </div>
                
            </div>
            
            
        </div>
    )
}

export default experienceComponent

/*

<div className="aboutCardContainer">
                <div className="aboutCardSummaryContainer">
                    <div className="aboutCardSummaryImageContainer">
                        <img className="aboutCardSummaryImage" src="me.png"/>
                    </div>
                    <div className="center">
                        <div className="aboutCardSummaryName bigText blueText">
                            <b>Ryan Moore</b>
                        </div>
                    </div>
                    <div className="center">
                        <div className="aboutCardSummaryLocation">
                            San Jose, CA
                        </div>
                    </div>
                </div>
                <div className="aboutCardContentContainer">
                    <div>
                        Although consultant is my official title, the nerd inside me ensures that I pursue programming and design in my spare
                    </div>
                </div>
            </div>

*/
