import React from 'react'
import BadgeComponent from './badgeComponent'
import '../../App.css';

const aboutComponent = () => {

    let badges = ['Java','React','Python']
    let title = 'Grouch'
    let description = 'Client for IoT device to display waste analytics'

    const returnBadges = () => {
        let badgeList = badges.map(badge => 
            <div>
                <BadgeComponent text={badge}/>
            </div>
        );
        return badgeList
    }

    return (
        <div className="cardComponent">
            <div className="introContainer">
                <div className="avatarSummaryGrid">
                    <div className="aboutCardSummaryImageContainer">
                        <img className="aboutCardSummaryImage" src="me.png"/>
                    </div>
                    <div className="center">
                        <div className="aboutCardSummaryName bigText blueText">
                            <b>Ryan Moore</b>
                        </div>
                    </div>
                    <div className="center">
                        <div className="aboutCardSummaryLocation">
                 
                        </div>
                    </div>
                </div>
                <div className="mediumText avatarMainGrid paddingLeft paddingRight">
                    <p>
                    Hey there I'm Ryan. I am an enterprise application developer proficient with integration, ETL, cloud infrastructure, and SaaS customization. I am especially passionate about leveraging my expertise with C# .Net, Azure, and the Power Platform! Keep scrolling to learn more.
                    </p>
                </div>

                <div className="aboutCardDivider dividerGrid1"/>


                <div className="marginLeft skillsSummaryGrid">
                    <div className="center">
                        <div className="aboutCardSummaryName bigText blueText">
                            Primary Skills
                        </div>
                    </div>
                </div>
                <div className="skillsMainGrid">
                    <div className="aboutCardBadgeContainer paddingLeft paddingRight">
                        <div className="blueBadgeComponent mediumText">
                            <b>.NET</b>
                        </div>
                        <div className="blueBadgeComponent mediumText">
                            <b>Azure</b>
                        </div>
                        <div className="blueBadgeComponent mediumText">
                            <b>Microsoft Dynamics</b>
                        </div>
                    </div>
                </div>
                
            
                <div className="aboutCardDivider dividerGrid2"></div>

                <div className="marginLeft educationSummaryGrid">
                    <div className="center">
                        <div className="aboutCardSummaryName bigText blueText">
                            Education
                        </div>
                    </div>
                </div>
                <div className="educationMainGrid paddingLeft paddingRight">
                    <div className="mediumText educationName">
                        <b>San Jose State University</b>
                    </div>
                    <div>
                        Bachelor of Science - Software engineering. Mathematics minor
                    </div>
                </div>
                <div className="aboutCardDivider dividerGrid3">

                </div>
                <div className="marginLeft bonusSummaryGrid">
                    <div className="center">
                        <div className="aboutCardSummaryName bigText blueText">
                            Bonus Details
                        </div>
                    </div>
                </div>
                <div className="bonusMainGrid">
                    <div className="educationName">
                        <ul className="mediumText paddingRight">
                            <li>Avid backpacker, rock climber, and outdoorsman</li>
                            <li>I own a DJI Mavic Mini drone named Mav</li>
                            <li>Powerlifting is my passion. Squat, bench, deadlift!</li>
                            <li>Competed in the 2016/2017 TESPA Collegiate Overwatch tournaments back when I played Esports in college. I miss every day of it</li>
                        </ul>
                    </div>
                    <div>
                        
                    </div>
                </div>
            </div>
            
            
        </div>
    )
}

export default aboutComponent

/*

<div className="aboutCardContainer">
                <div className="aboutCardSummaryContainer">
                    <div className="aboutCardSummaryImageContainer">
                        <img className="aboutCardSummaryImage" src="me.png"/>
                    </div>
                    <div className="center">
                        <div className="aboutCardSummaryName bigText blueText">
                            <b>Ryan Moore</b>
                        </div>
                    </div>
                    <div className="center">
                        <div className="aboutCardSummaryLocation">
                            San Jose, CA
                        </div>
                    </div>
                </div>
                <div className="aboutCardContentContainer">
                    <div>
                        Although consultant is my official title, the nerd inside me ensures that I pursue programming and design in my spare
                    </div>
                </div>
            </div>




                            <div className="marginLeft skillsSummaryGrid">
                    <div className="center">
                        <div className="aboutCardSummaryName bigText blueText">
                            Primary Skills
                        </div>
                    </div>
                </div>
                <div className="skillsMainGrid">
                    <div className="aboutCardBadgeContainer paddingLeft paddingRight">
                        <div className="blueBadgeComponent mediumText">
                            <b>Microsoft Dynamics</b>
                        </div>
                        <div className="blueBadgeComponent mediumText">
                            <b>SQL Server</b>
                        </div>
                        <div className="blueBadgeComponent mediumText">
                            <b>Power Platform Development</b>
                        </div>
                        <div className="blueBadgeComponent mediumText">
                            <b>MSBI</b>
                        </div>
                        <div className="blueBadgeComponent mediumText">
                            <b>Consulting</b>
                        </div>
                    </div>
                </div>

*/