import React from 'react'
import BadgeComponent from './badgeComponent'
import { FaGithub } from 'react-icons/fa';
const cardComponent = ({backgroundColor, badges,title,description,imgSrc,url}) => {

    const returnBadges = () => {
        let badgeList = badges.map(badge => 
            <div>
                <BadgeComponent text={badge}/>
            </div>
        );
        return badgeList
    }

    return (
        <div className="cardComponent">
            <div className="projectCardHeader">

                <div className="displayFlex blueText bigText projectCardTitle">
                    <div className="displayFlex">
                        <b>{title}</b>
                    </div>
                    <a href={url} className="marginLeft">
                        <FaGithub/>
                    </a>
                </div>
                <div className="mediumText projectCardDescription">
                    {description}
                </div>
                <div className="marginRight marginLeft projectCardBadgeList">
                    {returnBadges()}
                </div>
            </div>
            <div style={{backgroundColor}} className="projectCardImageContainer">
                <img className="projectCardImage" src={imgSrc} />
            </div>
        </div>
    )
}

export default cardComponent