import React, {useState, useEffect} from 'react';

import './Dashboard.css';
import '../../App.css'

const Footer = () => {
    /*return (
        
        <div className="footerContainer">
            <div className="grayText mediumText footerSection">
            </div>
            <div className="grayText mediumText flex">
                <div className="footerFlexSection">
                </div>
            </div>
        </div>
        
    )
    */
   return <div></div>
}

export default Footer