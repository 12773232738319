import './styles.css';

import React, {useState, useEffect} from 'react';

const badgeComponent = ({text}) => {
    return (
        <div className="badgeComponent">
            <b>{text}</b>
        </div>
    )
}

export default badgeComponent