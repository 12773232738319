import React from 'react';
import BadgeComponent from './badgeComponent';
import { FaMicrosoft, FaChartLine, FaCode, FaBook, FaLaptop, FaAws, FaCodeBranch, FaServer, FaReact, FaDatabase, FaCircle, FaFontAwesome } from 'react-icons/fa';

const skillComponent = ({iconParam, title, badges}) => {

    let icon = <FaCode/>

    const returnBadges = () => {
        let badgeList = badges.map(badge => 
            <div>
                <BadgeComponent text={badge}/>
            </div>
        );
        return badgeList
    }

    switch(iconParam) {
        case "FaCode": icon = <FaCode/>; break;
        case "FaCodeBranch" : icon = <FaCodeBranch/>; break;
        case "FaMicrosoft" : icon = <FaMicrosoft/>; break;
        case "FaServer" : icon = <FaServer/>; break;
        case "FaReact" : icon = <FaReact/>; break;
        case "FaDatabase" : icon = <FaDatabase/>; break;
        case "FaLaptop" : icon = <FaLaptop/>; break;
        case "FaBook" : icon = <FaBook/>; break;
        case "FaChartLine" : icon = <FaChartLine/>; break;
        //default: icon = <FaCircle/>
    }

    return(
        <div className="badgeContainer">
                    <div className="displayFlex bigText blueText badgeContainerTitle">
                        <div className="marginRight">
                            {icon}
                        </div>
                        <div >
                            <b>{title}</b>
                        </div>
                        
                    </div>
                    <div className="badgeContainerDivider"/>
                    <div className="skillCategoryContainer">   
                        {returnBadges()}
                    </div>
        </div>
    )
}

export default skillComponent