import React from 'react';
import './App.css';

import Dashboard from './components/Dashboard/Dashboard';

import { BrowserRouter as Router, Route } from 'react-router-dom'

function App() {
  return (
    <div className="App-header">
      <Router>
        <Route path='/' exact component={Dashboard} />
      </Router>
    </div>
    
  );
}

export default App;
